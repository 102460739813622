"use client";

import { useUser } from "@clerk/nextjs";
import { User, UserContext, UserGroup } from "./user-context";
import { useCallback, useEffect, useState } from "react";
import { MobileTheme } from "@/app/types";
import {
  MOBILE_THEME_EXECUTIVE_DARK_GREEN,
  MOBILE_THEME_EXECUTIVE_GREEN,
} from "../mobile/styles/mobileTheme";
import { useQuery } from "@tanstack/react-query";
import { getCartrackedUserByEmail } from "@/lib/functions/get-user-query";

export const UserContextWrapper = ({ children }) => {
  const { user: clerkUser, isSignedIn, isLoaded } = useUser();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<User>();
  const [userGroups, setUserGroups] = useState<UserGroup[]>([]);
  const [hasAdminAccess, setHasAdminAccess] = useState<boolean>(false);
  const [primaryUserGroup, setPrimaryUserGroup] = useState<UserGroup>();

  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [mobileTheme, setMobileTheme] = useState<MobileTheme>(
    MOBILE_THEME_EXECUTIVE_GREEN,
  );

  const {
    data,
    isLoading: userIsLoading,
    isSuccess,
  } = useQuery({
    queryKey: ["user", clerkUser?.primaryEmailAddress?.emailAddress],
    queryFn: () =>
      getCartrackedUserByEmail(
        clerkUser?.primaryEmailAddress?.emailAddress || "",
      ),
    enabled: !!clerkUser?.primaryEmailAddress?.emailAddress,
    retry: 4,
    retryDelay: 1000,
  });

  useEffect(() => {
    if (!isSignedIn || !isLoaded || !clerkUser) {
      return;
    }

    // const fetchUser = async () => {
    //   if (!clerkUser.primaryEmailAddress) {
    //     console.error("No primary email address found");
    //     console.log(clerkUser.primaryEmailAddress)
    //     setIsLoading(false);
    //     return;
    //   }
    //   setIsLoading(true);
    //   const response = await fetch(
    //     `/v2/api/user?email=${encodeURIComponent(clerkUser.primaryEmailAddress.emailAddress)}`,
    //   );

    //   if (!response.ok) {
    //     console.error("Failed to fetch user");
    //     console.error(response.statusText);
    //     setIsLoading(false);
    //     return;
    //   }
    //   const data = await response.json();
    //   setUser({
    //     ...data,
    //     id: data._id,
    //   });
    //   setIsLoading(false);
    // };

    // fetchUser();
  }, [isSignedIn, isLoaded, clerkUser]);

  useEffect(() => {
    if (!isSuccess && !userIsLoading) {
      setUser(undefined);
      setIsLoading(false);
    }

    if (isSuccess && data) {
      setUser({
        ...data,
        id: data._id,
      });
      setIsLoading(false);
    }
  }, [data, isSuccess, userIsLoading]);

  const getUserGroups = useCallback(
    async (dealershipId: string) => {
      if (!user) {
        return [];
      }

      const response = await fetch(
        `/v2/api/user/${user.id}/groups?dealershipId=${dealershipId}`,
      );
      if (!response.ok) {
        console.error("Failed to fetch user groups");
        return [];
      }

      const data: UserGroup[] = await response.json();

      return data;
    },
    [user],
  );

  // useEffect(() => {
  //   if (!user) {
  //     return;
  //   }

  //   const fetchUserGroups = async () => {
  //     let dealershipId = Cookies.get('dealershipId');

  //     if (!dealershipId) {
  //       dealershipId = user.dealershipIds[0];
  //     }

  //     if (!dealershipId) {
  //       console.error('No dealershipId found');
  //       return;
  //     }

  //     const response = await fetch(`/v2/api/user/${user.id}/groups?dealershipId=${dealershipId}`);
  //     if (!response.ok) {
  //       console.error('Failed to fetch user groups');
  //       return;
  //     }

  //     const data: UserGroup[] = await response.json();

  //     setUserGroups(data);
  //   };

  //   fetchUserGroups();
  // }, [user, Cookies]);

  // Mobile detection
  useEffect(() => {
    const handleDeviceDetection = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const isMobileDev = /iphone|ipod|android|blackberry|windows phone/g.test(
        userAgent,
      );
      const isTablet =
        /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent);

      if (isMobileDev || isTablet) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    handleDeviceDetection();
    window.addEventListener("resize", handleDeviceDetection);

    return () => {
      window.removeEventListener("resize", handleDeviceDetection);
    };
  }, []);

  //dark mode detection
  useEffect(() => {
    const matchMedia = window.matchMedia("(prefers-color-scheme: dark)");

    const handleChange = (e) => {
      if (e.matches) {
        setMobileTheme(MOBILE_THEME_EXECUTIVE_DARK_GREEN);
      } else {
        setMobileTheme(MOBILE_THEME_EXECUTIVE_GREEN);
      }
    };
    handleChange(matchMedia);
    matchMedia.addEventListener("change", handleChange);

    return () => {
      matchMedia.removeEventListener("change", handleChange);
    };
  }, []);

  const getPrimaryUserGroup = useCallback(
    (dealershipId) => {
      if (!userGroups || userGroups.length < 1) {
        return;
      }

      return userGroups
        .filter((group) => group.dealershipId === dealershipId)
        .sort((a, b) => a.priority - b.priority)[0];
    },
    [userGroups],
  );

  const ctx: UserContext = {
    isLoading:
      typeof isSignedIn === "undefined" ? true : isSignedIn && userIsLoading,
    isAuthed: isSignedIn && user ? true : false,
    user,
    userGroups,
    setUser: () => {},
    setUserGroups,
    getPrimaryUserGroup,
    hasAdminAccess: false,
    setHasAdminAccess: () => {},
    getAuthToken: () => "",
    getUserGroups,
    isMobile,
    setIsMobile,
    mobileTheme,
    setMobileTheme,
  };

  return <UserContext.Provider value={ctx}>{children}</UserContext.Provider>;
};
