"use client";

import { MobileTheme } from "@/app/types";
import { createContext } from "react";
import { MOBILE_THEME_LIGHT } from "../mobile/styles/mobileTheme";

export type User = {
  id: string;
  email: string;
  name: string;
  internalUser: boolean;
  permissions: Record<string, string[]>;
  dealershipIds: string[];
};

export type UserGroup = {
  dealershipId: string;
  id: string;
  name: string;
  permissions: string[];
  priority: number;
};

export interface UserContext {
  isLoading: boolean;
  isAuthed: boolean;
  isMobile: boolean;
  setIsMobile: (isMobile: boolean) => void;
  user: User | undefined;
  userGroups: UserGroup[];

  mobileTheme: MobileTheme;
  setMobileTheme: (theme: MobileTheme) => void;

  setUser: (user: User) => void;
  setUserGroups: (userGroups: UserGroup[]) => void;
  getPrimaryUserGroup: (dealershipId: string) => UserGroup | undefined;
  // setPrimaryUserGroup: (userGroup: UserGroup) => void;
  hasAdminAccess: boolean;
  setHasAdminAccess: (hasAdminAccess: boolean) => void;
  getAuthToken: () => string | null;
  getUserGroups: (userId: string) => Promise<UserGroup[]>;
}

export const UserContext = createContext<UserContext>({
  isLoading: true,
  isAuthed: false,
  isMobile: false,
  setIsMobile: (isMobile: boolean) => {},
  setMobileTheme: (theme: MobileTheme) => {},
  mobileTheme: MOBILE_THEME_LIGHT,
  user: {
    id: "",
    email: "",
    name: "",
    permissions: {},
    internalUser: false,
    dealershipIds: [],
  },
  getPrimaryUserGroup: (id: string) => undefined,
  userGroups: [],
  setUserGroups: (userGroups: UserGroup[]) => {},
  setUser: () => {},
  hasAdminAccess: false,
  setHasAdminAccess: () => {},
  getAuthToken: () => "",
  getUserGroups: async () => [],
});
