export const MOBILE_THEME_LIGHT = {
  LIGHT: "rgb(184, 207, 255)",
  MED: "rgb(144, 179, 255)",
  MED2: "rgb(130 174 255)",
  SELECTED: "rgb(108, 156, 255)",
  MEDDARK: "rgb(83, 141, 255)",
  DARK: "rgb(247, 250, 255)",
  EXTRALIGHT: "rgb(247, 250, 255)",
  TRACKEDGREEN: "rgb(84, 218, 112)",
  SLIDE: "rgb(83 141 255)",
  BACKGROUND: "#FFFF",
  FONT1: "#FFFF",
  FONT2: "#000",
  FONT3: "rgb(117 117 117)",
  FONTWHITE: "#FFFF",
  SHADOW: "4px 4px 15px rgba(0, 0, 0, 0.2)",
  NEW: "#5278f6",
  USED: "#f4ba4e",
};
const MOBILE_THEME_DARK = {
  LIGHT: "rgb(137, 236, 158)",
  MED: "rgb(94, 223, 121)",
  MED2: "rgb(82, 218, 112)",
  SLIDE: "rgb(27, 201, 63)",
  SELECTED: "rgb(58 203 90)",
  DARK: "rgb(127, 239, 150)",
  EXTRALIGHT: "rgb(101, 101, 101)",
  TRACKEDGREEN: "rgb(84, 218, 112)",
  BACKGROUND: "rgb(85 85 85)",
  SHADOW: "4px 4px 15px rgba(0, 0, 0, 0.3)",
  FONT1: "#FFFF",
  FONT2: "#FFFF",
  FONT3: "#FFFF",
  FONTWHITE: "#FFFF",
  NEW: "rgb(241, 196, 15)",
  USED: "rgb(68, 180, 120)",
};

const MOBILE_THEME_DARK_EXEC = {
  LIGHT: "rgb(210 214 216)", // Light Grey for a clean and crisp background
  MED: "rgb(30, 199, 66)", // Slate Grey for medium importance elements
  MED2: "rgb(141 153 159)", // Darker Slate Grey for secondary elements
  SELECTED: "rgb(30, 199, 66)", // Vibrant Orange for selected items and accents
  DARK: "rgb(38, 50, 56)", // Charcoal Grey, for a solid, grounding contrast
  EXTRALIGHT: "rgb(101, 101, 101)", // Off White for card surfaces and interactive elements
  TRACKEDGREEN: "rgb(25, 118, 210)", // Brilliant Blue for tracking progress or alerts
  SLIDE: "rgb(69, 90, 100)", // Bright Blue for sliders and interactive elements
  BACKGROUND: "rgb(85 85 85)",
  SHADOW: "4px 4px 15px rgba(0, 0, 0, 0.3)",
  FONT1: "#FFFF",
  FONT2: "#FFFF",
  FONT3: "#FFFF",
  FONTWHITE: "#FFFF",
  NEW: "rgb(82, 218, 112)", // Amber for highlighting new items, signifying attention
  USED: "rgb(158, 158, 158)", // Granite Grey for used items, denoting sophistication
};

const MOBILE_THEME_WARM = {
  LIGHT: "rgb(255, 204, 153)", // Soft Peach
  MED: "rgb(255, 179, 128)", // Coral
  MED2: "rgb(242, 160, 105)", // Salmon
  SELECTED: "rgb(255, 128, 77)", // Burnt Orange
  DARK: "rgb(230, 115, 55)", // Deep Orange
  EXTRALIGHT: "rgb(255, 229, 204)", // Lightest Peach
  TRACKEDGREEN: "rgb(230, 90, 50)", // Rusty Red
  SLIDE: "rgb(230, 115, 55)", // Consistent with DARK for UI harmony
  BACKGROUND: "rgb(255, 248, 240)", // Off White, warm tone
  SHADOW: "4px 4px 15px rgba(0, 0, 0, 0.15)", // Softer shadow
  FONT1: "#FFFF", // Dark grey for primary text
  FONT2: "#555", // Medium grey for secondary text
  FONT3: "#777", // Light grey for tertiary information
  FONTWHITE: "#FFFF",
  NEW: "rgb(235, 111, 52)",
  USED: "rgb(204, 85, 0)",
};

export const MOBILE_THEME_SLEEK = {
  LIGHT: "rgb(223 231 243)", // Light Grey for a sleek and modern feel
  MED: "rgb(139 152 179)", // Steel Blue for medium importance elements
  MED2: "rgb(133 148 180)", // Dark Blue-Grey for secondary elements
  SELECTED: "rgb(211, 47, 47)", // Red for selected items and accents
  DARK: "rgb(34, 40, 49)", // Dark Grey, almost black, for strong contrast
  EXTRALIGHT: "rgb(255, 255, 255)", // Pure White for backgrounds and surfaces
  TRACKEDGREEN: "rgb(231, 76, 60)", // Brighter Red for tracking progress or alerts
  SLIDE: "rgb(39, 60, 117)", // Dark Blue for sliders and interactive elements
  BACKGROUND: "rgb(249, 249, 249)", // Off White for general background, less stark
  SHADOW: "4px 4px 15px rgba(0, 0, 0, 0.25)", // Pronounced shadow for depth
  FONT1: "#333333", // Dark grey for primary text
  FONT2: "#555555", // Medium grey for secondary text
  FONT3: "#BBBBBB", // Light grey for tertiary information and placeholders
  FONTWHITE: "#FFFF",
  NEW: "#5278f6",
  USED: "#f4ba4e",
};

export const MOBILETHEMEEXECUTIVE = {
  LIGHT: "rgb(210 214 216)", // Light Grey for a clean and crisp background
  MED: "rgb(96, 125, 139)", // Slate Grey for medium importance elements
  MED2: "rgb(141 153 159)", // Darker Slate Grey for secondary elements
  SELECTED: "rgb(255, 87, 34)", // Vibrant Orange for selected items and accents
  DARK: "rgb(38, 50, 56)", // Charcoal Grey, for a solid, grounding contrast
  EXTRALIGHT: "rgb(250, 250, 250)", // Off White for card surfaces and interactive elements
  TRACKEDGREEN: "rgb(25, 118, 210)", // Brilliant Blue for tracking progress or alerts
  SLIDE: "rgb(69, 90, 100)", // Bright Blue for sliders and interactive elements
  BACKGROUND: "rgb(238, 238, 238)", // Cool Grey for a subtle background distinction
  SHADOW: "4px 4px 20px rgba(0, 0, 0, 0.3)", // Slightly bolder shadow for depth
  FONT1: "#212121", // Almost Black for primary text, adding contrast
  FONT2: "#616161", // Darker Medium Grey for secondary text
  FONT3: "#9E9E9E", // Mid Grey for tertiary information and placeholders
  FONTWHITE: "#FFFFFF", // Pure White for texts on dark backgrounds
  NEW: "rgb(255, 193, 7)", // Amber for highlighting new items, signifying attention
  USED: "rgb(158, 158, 158)", // Granite Grey for used items, denoting sophistication
};
export const MOBILE_THEME_EXECUTIVE_GREEN = {
  LIGHT: "rgb(210 214 216)", // Light Grey for a clean and crisp background
  MED: "#43d662",
  MED2: "rgb(141 153 159)", // Darker Slate Grey for secondary elements
  SELECTED: "#43d662",
  DARK: "rgb(38, 50, 56)", // Charcoal Grey, for a solid, grounding contrast
  EXTRALIGHT: "rgb(248, 248, 248)", // Off White for card surfaces and interactive elements
  TRACKEDGREEN: "#43d662", // Brilliant Blue for tracking progress or alerts
  SLIDE: "rgb(69, 90, 100)", // Bright Blue for sliders and interactive elements
  BACKGROUND: "rgb(255, 255, 255)", // Off White for general background, less stark
  SHADOW: "4px 4px 20px rgba(0, 0, 0, 0.2)", // Slightly bolder shadow for depth
  FONT1: "#212121", // Almost Black for primary text, adding contrast
  FONT2: "#616161", // Darker Medium Grey for secondary text
  FONT3: "#9E9E9E", // Mid Grey for tertiary information and placeholders
  FONTWHITE: "#FFFFFF", // Pure White for texts on dark backgrounds
  //NEW: "rgb(84, 218, 112)", // Amber for highlighting new items, signifying attention
  NEW: "#5278f6",
  USED: "#f4ba4e",
  //USED: "rgb(158, 158, 158)", // Granite Grey for used items, denoting sophistication
};
export const MOBILE_THEME_EXECUTIVE_DARK_GREEN = {
  LIGHT: "rgb(210 214 216)", // Light Grey for a clean and crisp background
  MED: "#43d662",
  MED2: "rgb(141 153 159)", // Darker Slate Grey for secondary elements
  SELECTED: "#43d662",
  DARK: "rgb(38, 50, 56)", // Charcoal Grey, for a solid, grounding contrast
  EXTRALIGHT: "rgb(101, 101, 101)", // Off White for card surfaces and interactive elements
  TRACKEDGREEN: "#43d662", // Brilliant Blue for tracking progress or alerts
  SLIDE: "rgb(69, 90, 100)", // Bright Blue for sliders and interactive elements
  BACKGROUND: `rgb(85 85 85)`,
  SHADOW: "4px 4px 15px rgba(0, 0, 0, 0.3)",
  FONT1: "#FFFF",
  FONT2: "#FFFF",
  FONT3: "#FFFF",
  FONTWHITE: "#FFFF",
  NEW: "#5278f6",
  USED: "#f4ba4e",
};
const THEME = MOBILE_THEME_SLEEK;
export const themeArray = [
  MOBILE_THEME_LIGHT,
  MOBILE_THEME_EXECUTIVE_GREEN,
  MOBILE_THEME_EXECUTIVE_DARK_GREEN,

  MOBILE_THEME_DARK,
  MOBILE_THEME_DARK_EXEC,
  MOBILE_THEME_WARM,
  MOBILE_THEME_SLEEK,
  MOBILE_THEME_EXECUTIVE_GREEN,
];
