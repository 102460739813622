import { createContext } from "react";
import { Dealership } from "./dealership-context-wrapper";

export interface DealershipContext {
  currentDealership: Dealership | undefined;
  setCurrentDealership: (dealership: Dealership) => void;
  dealerships: Dealership[];
  setDealerships: (dealerships: Dealership[]) => void;
  hasFeature: (feature: string) => boolean;
}

export type DealershipConfiguration = {
  backendGPUTarget: number;
  backendGrossTarget: number;
  businessDays: number;
  commentNotificationEmailEnabled: boolean;
  createdTimestamp: string; // ISO 8601 format date string
  dailyRecapEmailEnabled: boolean;
  dailyRecapEmailUserList: string[];
  dueBillNotificationEmailEnabled: boolean;
  frontendGPUTarget: number;
  grossChangeEmailUserList: string[];
  grossChangeNotificationEmailEnabled: boolean;
  grossChangeThreshold: number;
  houseDealsEnabled: boolean;
  lenders: Array<Lender>;
  models: {
    [make: string]: string[];
  };
  products: Array<{ [key: string]: any }>;
  salesGrossTarget: number;
  specializedBusinessUnits: string[];
  timezone: {
    rawOffset: number;
    rawOffsetDiff: number;
    checksum: number;
    dstSavings: number;
    transitions: Array<{ [key: string]: any }>;
  };
  unitsSoldTarget: number;
  useCustomVehicleDB: boolean;
  _id: string;
};
export type Lender = {
  name: string;
};

export const DealershipContext = createContext<DealershipContext>({
  currentDealership: {
    name: "",
    key: "",
    id: "",
    active: false,
    dealershipConfiguration: {
      backendGPUTarget: 0,
      backendGrossTarget: 0,
      businessDays: 0,
      commentNotificationEmailEnabled: false,
      createdTimestamp: "",
      dailyRecapEmailEnabled: false,
      dailyRecapEmailUserList: [],
      dueBillNotificationEmailEnabled: false,
      frontendGPUTarget: 0,
      grossChangeEmailUserList: [],
      grossChangeNotificationEmailEnabled: false,
      grossChangeThreshold: 0,
      houseDealsEnabled: false,
      lenders: [],
      models: {},
      products: [],
      salesGrossTarget: 0,
      specializedBusinessUnits: [],
      timezone: {
        rawOffset: 0,
        rawOffsetDiff: 0,
        checksum: 0,
        dstSavings: 0,
        transitions: [],
      },
      unitsSoldTarget: 0,
      useCustomVehicleDB: false,
      _id: "",
    },
  },
  setCurrentDealership: () => {},
  dealerships: [],
  setDealerships: () => {},
  hasFeature: () => false,
});
